export {
  default as scaleBand,
  point as scalePoint
} from "./src/band";

export {
  default as scaleIdentity
} from "./src/identity";

export {
  default as scaleLinear
} from "./src/linear";

export {
  default as scaleLog
} from "./src/log";

export {
  default as scaleOrdinal,
  implicit as scaleImplicit
} from "./src/ordinal";

export {
  default as scalePow,
  sqrt as scaleSqrt
} from "./src/pow";

export {
  default as scaleQuantile
} from "./src/quantile";

export {
  default as scaleQuantize
} from "./src/quantize";

export {
  default as scaleThreshold
} from "./src/threshold";

export {
  default as scaleTime
} from "./src/time";

export {
  default as scaleUtc
} from "./src/utcTime";

export {
  default as schemeCategory10
} from "./src/category10";

export {
  default as schemeCategory20b
} from "./src/category20b";

export {
  default as schemeCategory20c
} from "./src/category20c";

export {
  default as schemeCategory20
} from "./src/category20";

export {
  default as interpolateCubehelixDefault
} from "./src/cubehelix";

export {
  default as interpolateRainbow,
  warm as interpolateWarm,
  cool as interpolateCool
} from "./src/rainbow";

export {
  default as interpolateViridis,
  magma as interpolateMagma,
  inferno as interpolateInferno,
  plasma as interpolatePlasma
} from "./src/viridis";

export {
  default as scaleSequential
} from "./src/sequential";
